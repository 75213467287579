<template>
  <main class="flex items-center justify-center w-full h-screen">
    <Converter />
  </main>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Converter from "./components/Converter.vue";

export default defineComponent({
  name: "App",
  components: {
    Converter
  }
});
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  overflow: hidden;
  height: 100vh;
}
</style>
