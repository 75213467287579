<template>
  <div class="overflow-hidden bg-white rounded-sm shadow-lg">
    <div class="w-full p-4 bg-fritz">
      <h1 class="font-sans text-2xl font-semibold text-white">
        FRITZ!Box Rufnummer Konvertierung
      </h1>
    </div>
    <div class="max-w-lg p-6">
      <transition
        name="fade"
        mode="out-in"
        class="flex flex-col space-y-6 transition-opacity"
      >
        <div
          v-if="
            fileSelected == Progress.Missing || fileSelected == Progress.Error
          "
          class="max-h-screen select"
        >
          <p class="font-sans text-base text-justify">
            Mit Hilfe dieses Tools können Rufnummer Datein in ein FRITZ!Box
            Telefonbuch konvertiert werden. Das Format der Rufnummer Datei
            sollte dabei wie folgt aussehen:
          </p>
          <span
            class="block p-3 font-mono text-left bg-gray-300 border-l-4 border-gray-500"
          >
            00390104211502,<br />
            00390109166000,<br />
            00390109166062,<br />
            00390495199783,
          </span>
          <div class="relative button">
            Rufnummer Datei auswählen...
            <input
              class="absolute inset-0 z-10 w-full opacity-0 cursor-pointer"
              type="file"
              name="numberFile"
              id="fileUpload"
              ref="fileSelect"
              @change="selectFile"
            />
          </div>
        </div>
        <div v-else-if="fileSelected == Progress.Selected" class="max-h-screen">
          <p class="font-sans text-base">
            Ingesamt wurden {{ numbers.length }} Nummern erkannt:
          </p>
          <ul class="overflow-y-scroll bg-gray-200 max-h-80">
            <li
              v-for="(number, index) in numbers"
              :key="number"
              class="overflow-hidden font-mono text-base text-left rounded-sm numberItem"
            >
              <span
                class="inline-block p-2 text-right text-white bg-gray-500 w-14"
                >{{ index + 1 }}:</span
              ><span class="inline-block p-2">{{ number }}</span>
            </li>
          </ul>
          <button class="button" @click="convertToPhonebook">
            Runterladen
          </button>
        </div>
        <div v-else-if="fileSelected == Progress.Finished" class="max-h-96">
          <p class="font-sans text-base text-justify">
            Das konvertierte Telefonbuch wird sofort runtergeladen...
          </p>
          <loading-spinner class="mx-auto" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { js2xml } from "xml-js";
import { Progress } from "@/@types";

export default defineComponent({
  name: "Converter",
  components: { LoadingSpinner },
  setup() {
    const fileSelected = ref<Progress>(Progress.Missing);

    const fileSelect = ref<HTMLInputElement | null>(null);
    const numbers = ref<string[]>([]);

    const startDownload = (downloadable: string) => {
      const filename = "Telefonbuch_Sperrnummern.xml";

      const blob = new Blob([downloadable], { type: "text/xml" });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        const el = document.createElement("a");
        el.download = filename;
        el.href = window.URL.createObjectURL(blob);
        el.dataset.downloadurl = ["text/xml", el.download, el.href].join(":");
        el.style.display = "none";

        document.body.appendChild(el);
        el.click();
        document.body.removeChild(el);

        setTimeout(() => {
          fileSelected.value = Progress.Missing;
        }, 3000);
      }
    };

    const convertToNumbers = (str: string) => {
      //TODO
      numbers.value = str.split(/[\D+]/g).filter(el => {
        return el.length > 0;
      });

      fileSelected.value = Progress.Selected;
    };

    const convertToPhonebook = () => {
      fileSelected.value = Progress.Finished;

      const mappedNumbers = numbers.value.map((number, index) => {
        return {
          category: {},
          person: {
            realName: {
              _text: "SPAM"
            }
          },
          telephony: {
            number: {
              _attributes: {
                type: "home",
                prio: 1
              },
              _text: number
            }
          },
          services: {},
          setup: {},
          features: {
            _attributes: {
              doorphone: 0
            }
          },
          // eslint-disable-next-line @typescript-eslint/camelcase
          mod_time: {
            _text: Date.now()
          },
          uniqueid: {
            _text: index
          }
        };
      });

      const phonebook = {
        _declaration: {
          _attributes: {
            version: "1.0",
            encoding: "utf-8"
          }
        },
        phonebooks: {
          phonebook: {
            contact: [...mappedNumbers]
          }
        }
      };

      const phonebookFile = js2xml(phonebook, { compact: true });
      setTimeout(() => {
        startDownload(phonebookFile);
      }, 1000);
    };

    const selectFile = () => {
      const files = fileSelect.value?.files;
      if (!files) return;

      const file = files.item(0);
      if (!file || file.type != "text/plain") {
        fileSelected.value = Progress.Error;
        return;
      }

      const reader = new FileReader();
      reader.addEventListener("load", e => {
        convertToNumbers(e.target?.result as string);
      });
      reader.readAsText(file);
    };

    return {
      fileSelected,
      selectFile,
      fileSelect,
      numbers,
      Progress,
      convertToPhonebook
    };
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button {
  @apply inline-block px-4 py-2 self-center overflow-hidden font-sans font-medium text-white rounded-sm shadow-md cursor-pointer bg-fritz hover:bg-fritz-light hover:shadow-lg focus:bg-fritz-dark;
}

.numberItem:not(:first-child) {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease, max-height 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  max-height: 0px;
}
</style>
