
import { defineComponent, ref } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { js2xml } from "xml-js";
import { Progress } from "@/@types";

export default defineComponent({
  name: "Converter",
  components: { LoadingSpinner },
  setup() {
    const fileSelected = ref<Progress>(Progress.Missing);

    const fileSelect = ref<HTMLInputElement | null>(null);
    const numbers = ref<string[]>([]);

    const startDownload = (downloadable: string) => {
      const filename = "Telefonbuch_Sperrnummern.xml";

      const blob = new Blob([downloadable], { type: "text/xml" });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        const el = document.createElement("a");
        el.download = filename;
        el.href = window.URL.createObjectURL(blob);
        el.dataset.downloadurl = ["text/xml", el.download, el.href].join(":");
        el.style.display = "none";

        document.body.appendChild(el);
        el.click();
        document.body.removeChild(el);

        setTimeout(() => {
          fileSelected.value = Progress.Missing;
        }, 3000);
      }
    };

    const convertToNumbers = (str: string) => {
      //TODO
      numbers.value = str.split(/[\D+]/g).filter(el => {
        return el.length > 0;
      });

      fileSelected.value = Progress.Selected;
    };

    const convertToPhonebook = () => {
      fileSelected.value = Progress.Finished;

      const mappedNumbers = numbers.value.map((number, index) => {
        return {
          category: {},
          person: {
            realName: {
              _text: "SPAM"
            }
          },
          telephony: {
            number: {
              _attributes: {
                type: "home",
                prio: 1
              },
              _text: number
            }
          },
          services: {},
          setup: {},
          features: {
            _attributes: {
              doorphone: 0
            }
          },
          // eslint-disable-next-line @typescript-eslint/camelcase
          mod_time: {
            _text: Date.now()
          },
          uniqueid: {
            _text: index
          }
        };
      });

      const phonebook = {
        _declaration: {
          _attributes: {
            version: "1.0",
            encoding: "utf-8"
          }
        },
        phonebooks: {
          phonebook: {
            contact: [...mappedNumbers]
          }
        }
      };

      const phonebookFile = js2xml(phonebook, { compact: true });
      setTimeout(() => {
        startDownload(phonebookFile);
      }, 1000);
    };

    const selectFile = () => {
      const files = fileSelect.value?.files;
      if (!files) return;

      const file = files.item(0);
      if (!file || file.type != "text/plain") {
        fileSelected.value = Progress.Error;
        return;
      }

      const reader = new FileReader();
      reader.addEventListener("load", e => {
        convertToNumbers(e.target?.result as string);
      });
      reader.readAsText(file);
    };

    return {
      fileSelected,
      selectFile,
      fileSelect,
      numbers,
      Progress,
      convertToPhonebook
    };
  }
});
