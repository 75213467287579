<template>
  <div
    class="w-8 h-8 border-4 border-dashed rounded-full animate-spin spinner"
  ></div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoadingSpinner"
});
</script>

<style scoped>
.spinner {
  border-color: #268edf;
}
</style>
